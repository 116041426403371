import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import PhotoGallery from './Components/PhotoGallery';
import Contact from './Components/Contact';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} ></Route>
        <Route exact path="/about" element={<About/>} ></Route>
        <Route exact path="/photogallery" element={<PhotoGallery/>} ></Route>
        <Route exact path="/contact" element={<Contact/>} ></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
