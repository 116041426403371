import React from 'react'
import slide1 from '../images/slide1.jpg'
import slide2 from '../images/slide2.jpg'
import slide3 from '../images/slide3.jpg'
import '../Carousel.css'

const Carousel = () => {
  return (
    <>
    <div className='container-fluid p-0'>
        <div id="carousel" className="carousel slide carousel-fade">
  {/* <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div> */}
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="1000">
      <img src={slide1} className="d-block w-100 mh-auto" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={slide2} className="d-block w-100 mh-auto" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={slide3} className="d-block w-100 h-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
    </>
  )
}

export default Carousel