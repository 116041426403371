import React from 'react'

const VisionMision = () => {
    const style ={
        back:{
            color: "white",
            backgroundColor : "rgb(227,0,22)"
        },
        // cardWidth:{
        //     width: "25rem",
        //     height :"15rem"
        // }
    }
  return (
    <>
    <div className='container card-group my-4 justify-content-center'>
        <div className="row row-cols-1 row-cols-md-2 g-4">
  <div className="col">
    <div className="card border-danger-subtle" style={style.cardWidth}>
        <h5 className="card-header text-center" style={style.back}>OUR VISION</h5>
      <div className="card-body text-center" >
        <p className="card-text">Perfect Chemicals strives to be recognized as a good industry providing high value products and services. The indispensable part of our vision is obviously taking customer satisfaction to new heights and create wealth and value for our stakeholders.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card border-danger-subtle"style={style.cardWidth}>
        <h5 className="card-header text-center" style={style.back}>COMMITMENT</h5>
      <div className="card-body text-center">
        <p className="card-text">Our commitment is strategically plan and co-ordinate our company's activities to be an effective low cost source of supply while maintaining required quality of the product in line with our own indigenous technology, such that we can customize our offer to make the unique needs of our customers.</p>
      </div>
    </div>
  </div>
  </div>
  </div>
    </>
  )
}

export default VisionMision