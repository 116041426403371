import React, {useState} from 'react'

const Contactform = () => {

  const style = {
    backgroundColor: "rgb(144,144,144)"
}

  const [data,setdata] = useState({name:"", email: "", subject: "", message: ""})

  const handleSubmit = async (e)=>{
    e.preventDefault();
    console.log(data)

    // Connection of frontend and backend
    const response = await fetch("https://perfect-api.onrender.com/contact", {
      method:'POST',
      headers:{
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name: data.name,email: data.email,subject: data.subject,message: data.message})
    });
    const json = await response.json();
    console.log(json);

    // Setfields empty after the email is sent
    setdata({name:"", email: "", subject: "", message: ""})
  }
  const onChange = (e)=>{
    setdata({...data,[e.target.name]:[e.target.value]})
  }
  return (
    <>
      <div className='container-fluid m-0 mt-5 py-5' style={style}>
        <div className='col-group mx-5 '>
        <div className='row'>      
        <form onSubmit={handleSubmit} className='col-md text-center card px-0'>
          <h3 className='text-center card-header'>
            Contact Us
          </h3>
          <div className='row my-2 card-body'>
            <div className='col'> 
              <input type='text' required minLength={3} id="name" name="name" value={data.name} onChange={onChange} placeholder='Enter Your Name'/>
            </div>
          </div>
          <div className='row my-2 card-body'>
            <div className='col'>
              <input type='email' required onChange={onChange} id="email" name="email" value={data.email} placeholder='Enter your Email'/>
            </div>
          </div>
          <div className='row my-2 card-body'>
            <div className='col'>
              <input type='text' required onChange={onChange} id="subject" name="subject" value={data.subject} placeholder='Subject of your email'/>
            </div>
          </div>
          <div className='row my-2 card-body'>
            <div className='col'>
              <textarea type='form-control' required onChange={onChange} id="message" name="message" value={data.message}  rows="3" cols="24" placeholder='How can we help you?'/>
            </div>
          </div>
          <div class='col justify-content-center mb-2'>
          <button type="submit" class="btn btn-primary">Send Email</button>
          </div>

        </form>
        <div className='col-md mt-3 mt-md-0'>
          <div className='row h-100'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d732.2715633570037!2d73.0378515125168!3d21.61801592546494!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be02348ccef620d%3A0x3b87a81973f737b3!2sPerfect%20Chemicals!5e0!3m2!1sen!2sin!4v1676899254379!5m2!1sen!2sin"   allowfullscreen="" title='map' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        </div>
      </div>
        </div>
        </div>
    </>
  )
}

export default Contactform