import React from 'react'
import image from '../images/Companyheader.svg'

const Companyheader = () => {

  return (
    <>
    <div className='container-fluid m-0 p-0 bg-dark'>
        <img src={image} className="mw-100 p-0 py-2 mt-2 bg-white"/>
    </div>
    </>
  )
}

export default Companyheader