import React from 'react'
import Footer from './Footer'
import Gallery from './Gallery'
import Navbar from './Navbar'

const PhotoGallery = () => {
  return (
    <>
        <Navbar/>
        <Gallery/>
        <Footer/>
    </>
  )
}

export default PhotoGallery