import React from 'react'
import Carousel from './Carousel'
import Carouselbottom from './Carouselbottom'
import Footer from './Footer'
import Navbar from './Navbar'
import VisionMision from './VisionMision'

const Home = () => {
  return (
    <>
        <Navbar/>
        <Carousel/>
        <Carouselbottom/>
        <VisionMision/> 
        <Footer/>
    </>
    )
}

export default Home