import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    const style ={
        backgroundColor: "rgba(0, 0, 0, 0.2)"
    }
  return (
    <>
<footer class="text-center text-lg-start bg-dark text-white">

  <section class="">
    <div class="container text-center text-md-start mt-5">
      <div class="row pt-3">
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3"></i>Perfect Chemicals    
          </h6>
          <p>
            Your stop for the manufacturing and Supplying of API Intermediates
          </p>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Basic Links
          </h6>
          <p>
            <Link to="/" class="text-reset text-decoration-none">Home</Link>
          </p>
          <p>
            <Link to="/about" class="text-reset text-decoration-none">About</Link>
          </p>
          <p>
            <Link to="/photogallery" class="text-reset text-decoration-none">Photo Gallery</Link>
          </p>
          <p>
            <Link to="/contact" class="text-reset text-decoration-none">Contact Us</Link>
          </p>
        </div>

        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p><i class="fas fa-home me-3"></i>Plot No. 4801/A/15, GIDC Ankleshwar</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            perfectchemical2014@gmail.com
          </p>
          <p ><i class="fas fa-phone me-3"></i>9913413451</p>
          <p><i class="fas fa-phone me-3"></i>7567151244</p>
        </div>
      </div>
    </div>
  </section>

  <div class="text-center p-4" style={style}>   
    © 2023 Copyright:
    <Link class="text-reset fw-bold" to="https://perfectchemicals.in/">Perfect Chemicals</Link>
  </div>
</footer>
    </>
  );
};

export default Footer;
