import React from 'react'
import { Link } from 'react-router-dom'
import navlogo from '../images/logo3.png'
import Companyheader from './Companyheader'

const Navbar = () => {
  return (
    <>
    <div className='container-fluid m-0 p-0 sticky-top'>
    <Companyheader/>
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"> <img src={navlogo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top"/>
 </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" data-target='#navbarNavDropdown' aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/photogallery">Photo Gallery</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact Us</Link>
        </li>
        
      </ul>
    </div>
  </div>
</nav>
</div>
    </>
  )
}

export default Navbar