import React from 'react'

const Carouselbottom = () => {
  return (
    <>
        <div className='container-fluid py-2 bg-dark' >
            <h2 className=' text-center text-white'>
                Perfect Chemicals
            </h2>
            <h5 className='text-center text-white'>
                Manufacturer and Supplier of API intermediates
            </h5>
            <h5 className='text-center text-white'>
                (Since 2014)
            </h5>
        </div>
    </>
  )
}

export default Carouselbottom