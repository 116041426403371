import React from 'react'
import Contactform from './Contactform'
import Navbar from './Navbar'
import Footer from './Footer'

const Contact = () => {
  return (
    <>
      <Navbar/>
      <Contactform/> 
      <Footer/>
    </>
  )
}

export default Contact