import React from "react";
import image from "../images/aboutcard.png";

const Aboutmid = () => {
  const style = {
    col: {
      backgroundColor: "rgb(144,144,144)",
    },
    wid: {
      maxWidth: "720px",
      textAlign: "justify",
    },
  };
  return (
    <>
      <div className="container-fluid py-2 mt-3" style={style.col}>
        <div class="mb-3 d-grid ">
          <div className="row align-items-center justify-content-center ">
            <div class="card-body col-md-8 p-5 fs-4 " style={style.wid}>
              <h2 class="card-header text-center mb-2 fw-bolder">About Us</h2>
              <p class="card-text">
                <div>
                  <span className="fw-bold">Inception: </span>
                  <span>2014</span>
                </div>
                <div>
                  <span className="fw-bold">Bussiness Type: </span>
                  <span>Manufacturers and Suppliers</span>
                </div>
                <div className="mb-3">
                  <span className="fw-bold">Area: </span>
                  <span>800 Sq. Mts</span>
                </div>
                Perfect Chemicals, with more than 8 years of experience in this
                field. We are the leading Manufacturer and Supplier of API
                Intermediates. Perfect Chemicals has earned a huge reputation by
                becoming a trusted medium to source Chemicals With production
                and QC QA facilities, Perfect Chemicals is one of the most
                reliable quality Pharmaceutical Intermediates manufacturer from
                Ankleshwar (Gujarat) India
              </p>
            </div>
            <div class="col-md-4 pt-4 ps-5 pe-0">
              <img src={image} class="img-fluid w-75 h-100" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutmid;
