import React from 'react'
import image1 from '../images/Condenser.jpg'
import image2 from '../images/Horizontal_boiler1.jpg'
import image3 from '../images/Centrifuge.jpg'
import image4 from '../images/Cooling_Tower.jpg'
import image5 from '../images/Reactor.jpg'
import image6 from '../images/Tray_Dryer.jpg'

const Gallery = () => {
    const style = {
        backgroundColor: "rgb(144,144,144)"
    }
  return (
    <>
    <div className='container-fluid m-0 text-center p-0 pt-3 mt-5' style={style}>
        <h1 className='font-weight-bold'>Equipments We Use</h1>       
        <div class="row row-cols-1 row-cols-md-2 g-4 m-5">
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image1} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title text-center fw-bolder">Condenser</h5>
      </div>
    </div>
  </div>
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image2} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title text-center fw-bolder">Horizontal Bolier</h5>
      </div>
    </div>
  </div>
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image3} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title text-center fw-bolder">Centrifuge</h5>
      </div>
    </div>
  </div>
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image4} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title text-center fw-bolder">Cooling Tower</h5>
      </div>
    </div>
  </div>
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image5} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title fw-bolder text-center">Reactor</h5>
      </div>
    </div>
  </div>
  <div class="col p-5 pt-0">
    <div class="card h-100 bg-dark text-white border-2">
      <img src={image6} class="card-img-top" alt="..."/>
      <div class="card-body">
        <h5 class="card-title fw-bolder text-center">Tray Dryer</h5>
      </div>
    </div>
  </div>
</div>
</div>

    </>
  )
}

export default Gallery